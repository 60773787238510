import { css } from "styled-components"

const sliderCss = css`
  .slick-track {
    margin-left: 0;
  }

  .slick-next,
  .slick-prev {
    position: absolute;
    display: block;
    padding: 0;
    background-color: #fff;
    z-index: 20;
    font-size: 0;
    line-height: 0;
    top: 130px;
    width: 56px;
    height: 56px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: #000;
    border: none;
    outline: 0;
    border-radius: 50%;
    transition: opacity 0.3s ease;
  }

  .slick-next:focus,
  .slick-next:hover,
  .slick-prev:focus,
  .slick-prev:hover {
    color: #000;
    outline: 0;
  }

  .slick-next:focus:before,
  .slick-next:hover:before,
  .slick-prev:focus:before,
  .slick-prev:hover:before {
    opacity: 1;
  }

  .slick-next.slick-disabled,
  .slick-prev.slick-disabled {
    opacity: 0;
  }

  .slick-next.slick-disabled:before,
  .slick-prev.slick-disabled:before {
    opacity: 0;
  }

  .slick-next:before,
  .slick-prev:before {
    font-size: 20px;
    line-height: 1;
    color: #ff00ff;
    transition: opacity 0.3s ease;
  }

  .slick-prev {
    left: 12px;
  }

  .slick-prev:before {
    content: "";
    position: absolute;
    background-image: url("data:image/svg+xml,%3Csvg width='19' height='31' viewBox='0 0 19 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 26.7849L7.8088 15.5937L19 4.4025L15.5462 0.94873L0.901251 15.5937L15.5462 30.2387L19 26.7849Z' fill='%2301476D'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    width: 18px;
    height: 30px;
    transform: translate(-60%, -50%);
  }

  .slick-next:before {
    content: "";
    position: absolute;
    background-image: url("data:image/svg+xml,%3Csvg width='19' height='31' viewBox='0 0 19 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 26.7849L11.1912 15.5937L0 4.4025L3.45377 0.94873L18.0987 15.5937L3.45377 30.2387L0 26.7849Z' fill='%2301476D'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    width: 18px;
    height: 30px;
    transform: translate(-40%, -50%);
  }

  .slick-next {
    right: 32px;
  }

  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }
`

export default sliderCss
