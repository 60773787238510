import React, { useEffect, useState, useRef } from "react"
import styled, {css} from "styled-components"
import PQueue from "p-queue"
import { hideVisually } from "polished"
import Icon from "./Player.Icon"
import PlayerProgress from "./Player.Progress"
import useInterval from "../../utils/useInterval"
import { createPlayback as create, updatePlayback } from "../../api/api"
import isLocalStorageNameSupported from "../../utils/isLocalStorageSupported"
import SkipBack from "./Player.SkipBack"
import SkipForward from "./Player.SkipForward"

const queue = new PQueue({
  concurrency: 1,
  interval: 1000
})
const analyticsInterval = 10 * 1000

const localStorageSupported = isLocalStorageNameSupported()
const localStorageProxy = localStorageSupported
  ? localStorage
  : {
      setItem: (key, value) => null,
      getItem: (key, value) => null
    }

const Player = ({
  mediaUrl,
  episodeId,
  autoPlay = false,
  className,
  bodyText,
  largePlayBtn,
  isDark,
  hideBodyTextBreakpoint,
  heading,
  showHeadingBreakpoint,
  isFeatured
}) => {
  const [playing, setPlaying] = useState(false)
  const [duration, setDuration] = useState(null)
  const [position, setPosition] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [percent, setPercent] = useState(0)
  const [saved, setSaved] = useState(
    localStorageProxy.getItem(`${episodeId}-progression`) || 0
  )
  const [playback, setPlayback] = useState(null)
  const audioPlayer = useRef(null)
  const requestRef = useRef(null)

  useEffect(() => {
    requestRef.current = requestAnimationFrame(renderSeekPos)
    return () => cancelAnimationFrame(requestRef.current)
  }, [])

  useEffect(() => {
    const createPlayback = async () => {
      await queue.add(async () => {
        const p = await create(
          episodeId,
          window.navigator.userAgent.replace(/\D+/g, "")
        )
        setPlayback(p.id)
      })
    }

    audioPlayer.current.addEventListener("loadedmetadata", (e) => {
      setDuration(e.target.duration)
      setLoaded(true)
      queue.add(() => {
        localStorageProxy.setItem(`${episodeId}-duration`, e.target.duration)
      })

      if (autoPlay) {
        audioPlayer.current.play()
        setPlaying(true)
      }
    })

    audioPlayer.current.addEventListener("timeupdate", (e) => {
      if (e.target.currentTime > 0) {
        // setPosition(e.target.currentTime)
        queue.add(() => {
          localStorageProxy.setItem(
            `${episodeId}-progression`,
            e.target.currentTime
          )
        })
      }
    })

    const saved = localStorageProxy.getItem(`${episodeId}-progression`)
    if (saved) {
      audioPlayer.current.currentTime = saved
    }

    audioPlayer.current.addEventListener("play", (e) => {
      if (!playback) {
        createPlayback()
      }
    })

    return (e) => {
      audioPlayer.current.removeEventListener("timeupdate", e)
      audioPlayer.current.removeEventListener("loadedmetadata", e)
      audioPlayer.current.removeEventListener("play", e)
    }
  }, [episodeId])

  useInterval(async () => {
    if (playback) {
      await updatePlayback(playback, position)
    }
  }, analyticsInterval)

  const getTime = (time) => {
    if (time === 0) {
      return "0:00"
    }
    if (!isNaN(time)) {
      return (
        Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2)
      )
    }
  }

  const handleScrubber = (value) => {
    const valueInSeconds = (value[0] / 100) * duration
    setPercent(value[0] / 100)
    audioPlayer.current.currentTime = valueInSeconds
  }

  const handleSkip = (skipSeconds) => {
    audioPlayer.current.currentTime = position + skipSeconds
  }

  const handlePlayBtn = () => {
    if (!playing) {
      audioPlayer.current.play()
      setPlaying(true)
    } else {
      audioPlayer.current.pause()
      setPlaying(false)
    }
  }

  const renderSeekPos = () => {
    setPosition(audioPlayer.current.currentTime)
    requestRef.current = requestAnimationFrame(renderSeekPos)
  }

  return (
    <Container className={className}>
      <div>
        <PlayButton
          onClick={() => handlePlayBtn()}
          type="button"
          isLarge={largePlayBtn}
          isDark={isDark}
          hideBodyTextBreakpoint={hideBodyTextBreakpoint}
        >
          <Icon
            icon={!loaded ? "loading" : playing ? "playing" : "paused"}
            isDark={isDark}
          />
        </PlayButton>
        <audio ref={audioPlayer} preload="metadata">
          <source src={mediaUrl} type="audio/mp3" />
        </audio>
      </div>

      {heading && (
        <Heading showHeadingBreakpoint={showHeadingBreakpoint} isFeatured={isFeatured}>
          {heading}
        </Heading>
      )}

      {bodyText && (
        <BodyText
          hideBodyTextBreakpoint={hideBodyTextBreakpoint}
          isFeatured={isFeatured}
        >
          {bodyText}
        </BodyText>
      )}

      <Controls isLarge={largePlayBtn}>
        <SkipButton onClick={() => handleSkip(-10)}>
          <Hidden>10 sekunder tilbake</Hidden>
          <SkipBack isDark={isDark} />
        </SkipButton>
        <TimeStamp isDark={isDark}>
          {position ? getTime(position) : "0.00"}
        </TimeStamp>

        <BarContainer>
          <PlayerProgress
            progression={position / duration}
            onChange={handleScrubber}
            isDark={isDark}
          />
        </BarContainer>
        <TimeStamp isDark={isDark}>
          {duration ? getTime(duration.toFixed(2)) : "NaN"}
        </TimeStamp>
        <SkipButton onClick={() => handleSkip(30)}>
          <Hidden>30 sekunder frem</Hidden>
          <SkipForward isDark={isDark} />
        </SkipButton>
      </Controls>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
`

const Heading = styled.h1`
  display: none;
  flex-basis: calc(100% - 36px);

  ${(p) =>
    p.isFeatured &&
    css`
      height: 32px;
      overflow: hidden;
    `}

  ${(p) =>
    p.showHeadingBreakpoint &&
    css`
      @media only screen and (max-width: ${p.showHeadingBreakpoint}px) {
        display: block;
        font-size: 14px;
        font-weight: bold;
        font-family: "Georgia", serif;
        color: #333333;
        margin: 0;
        padding-left: 9px;
      }
    `}
`

const BodyText = styled.p`
  margin: 0 0 0 12px;
  flex-basis: calc(100% - 48px - 12px);
  font-size: 15px;
  line-height: 1.3;
  ${p => p.isFeatured && css`
    height: 57px;
    overflow: hidden;
  `}

  ${p =>
    p.hideBodyTextBreakpoint &&
    css`
      @media only screen and (max-width: ${p.hideBodyTextBreakpoint}px) {
        display: none;
      }
    `}
`

const Hidden = styled.span`
  ${hideVisually}
`

const SkipButton = styled.button`
  min-height: 0;
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
`

const BarContainer = styled.div`
  flex-basis: 100%;
`

const PlayButton = styled.button`
  display: block;
  width: ${(p) => (p.isLarge ? "48px" : "36px")};
  height: ${(p) => (p.isLarge ? "48px" : "36px")};
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  border: 0;

  &:focus,
  &:active,
  &:hover {
    outline: 0;
  }

  @media only screen and (max-width: 350px) {
    width: ${(p) => (p.isLarge ? "36px" : "32px")};
    height: ${(p) => (p.isLarge ? "36px" : "32px")};
  }

  ${(p) =>
    p.hideBodyTextBreakpoint &&
    css`
      @media only screen and (max-width: ${p.hideBodyTextBreakpoint}px) {
        width: 36px;
        height: 36px;
      }
    `}
`

const TimeStamp = styled.span`
  display: inline-block;
  color: ${p => (p.isDark ? "#fff" : "#767676")};
  padding: 0 6px;
`

const Controls = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 100%;
  padding-left: 12px;
  margin-top: ${p => (p.isLarge ? "12px" : "0")};
`

export default Player
