import React from "react"
import styled from "styled-components"

const SkipForward = ({ icon, isDark }) => (
  <Icon
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.99996 0C4.03838 0 0 4.0384 0 9C0 13.9616 4.03838 18 8.99996 18C13.9615 18 17.9999 13.9616 17.9999 9C18.0013 8.90062 17.983 8.80194 17.9459 8.70972C17.9088 8.61749 17.8538 8.53355 17.784 8.46277C17.7143 8.392 17.6311 8.33579 17.5394 8.29743C17.4477 8.25907 17.3493 8.23932 17.2499 8.23932C17.1505 8.23932 17.0521 8.25907 16.9604 8.29743C16.8687 8.33579 16.7856 8.392 16.7158 8.46277C16.646 8.53355 16.591 8.61749 16.5539 8.70972C16.5169 8.80194 16.4985 8.90062 16.4999 9C16.4999 13.1509 13.1508 16.5 8.99996 16.5C4.84908 16.5 1.49999 13.1509 1.49999 9C1.49999 4.8491 4.84908 1.5 8.99996 1.5C10.8839 1.5 12.5928 2.19827 13.9086 3.34131L12 5.25H16.4999V0.75L14.9706 2.2793C13.3803 0.86653 11.2903 0 8.99996 0ZM6.70603 6.26074C5.47903 6.26074 4.6879 6.91989 4.6699 7.95264H5.88865C5.90365 7.53864 6.21402 7.26416 6.67527 7.26416C7.14401 7.26416 7.43405 7.50197 7.43405 7.88672C7.43405 8.27147 7.13773 8.52393 6.68698 8.52393H6.03953V9.45117H6.68845C7.20145 9.45117 7.51169 9.69946 7.51169 10.106C7.51169 10.502 7.18227 10.7725 6.70603 10.7725C6.19678 10.7725 5.87149 10.5234 5.85349 10.1206H4.57176C4.61901 11.1571 5.44956 11.8315 6.68405 11.8315C7.97705 11.8315 8.87838 11.164 8.87838 10.2085C8.87838 9.5275 8.41207 9.04942 7.68307 8.97217V8.90186C8.27632 8.79161 8.68356 8.31186 8.68356 7.70361C8.68356 6.85011 7.87752 6.26074 6.70603 6.26074ZM11.9384 6.26221C10.5764 6.26221 9.7192 7.28319 9.7192 9.03369C9.7192 10.7842 10.5652 11.8308 11.9384 11.8315C13.3079 11.8315 14.1577 10.7811 14.1577 9.03809C14.1577 7.28009 13.2974 6.26221 11.9384 6.26221ZM11.9384 7.29492C12.4514 7.29492 12.7954 7.87269 12.7954 9.03369C12.7954 10.2022 12.4514 10.7988 11.9384 10.7988C11.4254 10.7988 11.0844 10.2022 11.0844 9.03369C11.0844 7.87269 11.4254 7.29492 11.9384 7.29492Z"
      fill={isDark ? "#fff" : "#00456f"}
    />
  </Icon>
)

const Icon = styled.svg`
  display: block;
`

export default SkipForward
