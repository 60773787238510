import React, { useState, useRef } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import styled, { css } from "styled-components"
import { AudioPlayerProvider } from "react-use-audio-player"
import CarouselEpisode from "./Carousel.Episode"
import CarouselEpisodeCurrent from "./Carousel.EpisodeCurrent"
import sliderCss from "./sliderCss"

const Carousel = ({ carousel }) => {
  const [currentEpisode, setCurrentEpisode] = useState(null)
  const [currentTab, setCurrentTab] = useState(carousel.tabs[0])
  const sliderRef = useRef(null)

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.8,
    slidesToScroll: 1,
    adaptiveHeight: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 860,
        settings: {
          slidesToShow: 3.2
        }
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 2.4
        }
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1.6
        }
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1.2
        }
      }
    ]
  }

  const handleTabChange = tab => {
    sliderRef.current.slickGoTo("0")
    setCurrentTab(tab)
  }

  return (
    <Outer>
      <Title>{carousel.title}</Title>
      <Tabs>
        {carousel.tabs.map(tab => {
          return (
            <Tab key={tab._key}>
              <TabButton
                current={tab._key === currentTab._key}
                onClick={() => handleTabChange(tab)}
              >
                {tab.tabName}
              </TabButton>
            </Tab>
          )
        })}
      </Tabs>
      <EpisodesContainer>
        <SliderStyling>
          <Slider {...sliderSettings} ref={sliderRef}>
            {currentTab.episodes.map(episode => {
              if (currentEpisode && currentEpisode.id === episode.id) {
                return (
                    <CarouselEpisodeCurrent
                      key={episode.id}
                      episode={episode}
                      setCurrentEpisode={setCurrentEpisode}
                    />
                )
              }
              return (
                <CarouselEpisode
                  key={episode.id}
                  episode={episode}
                  setCurrentEpisode={setCurrentEpisode}
                />
              )
            })}
          </Slider>
        </SliderStyling>
      </EpisodesContainer>
    </Outer>
  )
}

const Outer = styled.article`
  max-width: 950px;
  margin: 0 auto;
`

const Tabs = styled.ul`
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0 0 16px;
`

const Tab = styled.li`
  padding: 0 6px 3px;

  &:first-child {
    padding-left: 0;
  }
`

const TabButton = styled.button`
  position: relative;
  border: 0;
  background-color: transparent;
  padding: 0;

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 20%;
    height: 2px;
    background-color: transparent;
    transform-origin: left center;
    transition: transform 0.2s ease, background 0.2s ease;
  }

  ${p =>
    p.current
      ? css`
          color: #000;

          &::after {
            background-color: #00456f;
            transform: scaleX(3);
          }
        `
      : css`
          color: #767676;
        `}

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }
`

const EpisodesContainer = styled.div`
  position: relative;
  margin: 0 0 0 -12px;

  &::after {
    content: "";
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 178px;
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
`

const SliderStyling = styled.div`
  ${sliderCss}
`

const Title = styled.h1`
  font-size: 23px;
  font-weight: bold;
  color: #00456f;
`

export default Carousel
