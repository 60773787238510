import { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import * as queryString from "query-string"

const usePlayerType = () => {
   const location = useLocation()
   const [isDark, setIsDark] = useState(null)

   useEffect(() => {
     const params = queryString.parse(location.search)
     setIsDark(!!params.isDark)
   }, [location])

   return {
     isDark
   }
}

export default usePlayerType
