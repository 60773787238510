import React from "react"
import styled, {keyframes} from "styled-components"

const PlayerIcon = ({ icon, isDark }) => {
  if (icon === "loading") {

    return (
      <LoaderWrapper isDark={isDark}>
        <Dot isDark={isDark} />
        <Dot isDark={isDark} />
        <Dot isDark={isDark} />
      </LoaderWrapper>
    )
  }
  if (icon === "paused" || icon === "stopped") {
    return (
      <Icon viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20.7275 14.7981L21.7846 14L20.7275 13.2019L11.6945 6.38197L10.0919 5.17205V7.18005L10.0919 20.82V22.828L11.6945 21.618L20.7275 14.7981ZM27 14C27 21.1797 21.1797 27 14 27C6.8203 27 1 21.1797 1 14C1 6.8203 6.8203 1 14 1C21.1797 1 27 6.8203 27 14Z"
          fill={isDark ? "#fff" :"#00456f"}
          stroke={isDark ? "#fff" :"#00456f"}
          strokeWidth="2"
        />
      </Icon>
    )
  }
  if (icon === "playing") {
    return (
      <Icon viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 0C21.7308 0 28 6.26925 28 14C28 21.7308 21.7308 28 14 28C6.26925 28 0 21.7308 0 14C0 6.26925 6.26925 0 14 0ZM8.6393 6.83352L13.1536 6.8335V21.1664H8.63932L8.6393 6.83352ZM14.8465 6.8335L19.3608 6.83347V21.1664H14.8465V6.8335Z"
          fill={isDark ? "#fff" :"#00456f"}
        />
      </Icon>
    )
  }
}


const lc = keyframes`
    0 {transform: translate(0,0);}
    50% {transform: translate(0,2px);}
    100% {transform: translate(0,-2px);}
`

const Icon = styled.svg`
  display: block;
`


const Dot = styled.div`
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${(p) => (p.isDark ? "#302F2F" : "#fff")};
  margin: 0 1px;

  &:nth-child(1) {
    animation: ${lc} 1s 0.1s ease-in-out infinite alternate;
  }

  &:nth-child(2) {
    animation: ${lc} 1s 0.2s ease-in-out infinite alternate;
  }

  &:nth-child(3) {
    animation: ${lc} 1s 0.3s ease-in-out infinite alternate;
  }
`

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${p => p.isDark ? "#fff" : "#00456f"};
  display: flex;
  justify-content: center;
  align-items: center;
`

export default PlayerIcon
