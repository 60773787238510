const isLocalStorageNameSupported = () => {
  try {
    const supported = ("localStorage" in window && window["localStorage"])
    if (supported) {
      localStorage.setItem("storage", "")
      localStorage.removeItem("storage")
      return supported
    }
  } catch (err) { return false }
}

export default isLocalStorageNameSupported