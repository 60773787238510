import axios from "axios"

export const API_URL = "https://bt-lytt-stats-robins.lytt.now.sh/"
const LATEST = "__latest"
const instance = axios.create({
  baseURL: API_URL
})

const mapData = item => item.data
export const getEpisode = async slug =>
  instance.get(`/episode/${slug || LATEST}`).then(mapData)

export const getCarousel = async slug =>
  instance.get(`/carousel/${slug || LATEST}`).then(mapData)

export const getLatest = async series =>
  instance.get(`/series/${series}/${LATEST}`).then(mapData)

export const createPlayback = async (episodeId, uniqueId) =>
  instance.post(`/episode/${episodeId}?uniqueId=${uniqueId}`).then(mapData)

export const updatePlayback = async (playbackId, position) =>
  instance.put(`/episode/${playbackId}?position=${position}`).then(mapData)
