import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { Range } from "react-range"

function getTrackBackground({ values, colors, min, max, rtl = false }) {
  const progress = values.map(value => ((value - min) / (max - min)) * 100)
  const middle = progress.reduce(
    (acc, point, index) =>
      `${acc}, ${colors[index]} ${point}%, ${colors[index + 1]} ${point}%`,
    ""
  )
  return `linear-gradient(to right, ${colors[0]} 0%${middle}, ${
    colors[colors.length - 1]
  } 100%)`
}

const PlayerProgress = ({ progression = 0, onChange, isDark }) => {
  const [value, setValue] = useState(0)

  useEffect(() => {
    setValue(!isNaN(progression) ? progression * 100 : 0)
  }, [progression])

  const handleChange = value => {
    setValue(value)
    onChange(value)
  }

  return (
    <div>
      <Range
        step={0.1}
        min={0}
        max={100}
        values={[value]}
        onChange={handleChange}
        renderTrack={({ props, children }) => (
          <Container
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
          >
            <Track ref={props.ref} currentValue={value} isDark={isDark}>
              {children}
            </Track>
          </Container>
        )}
        renderThumb={({ props, isDragged }) => (
          <Thumb isDragged={isDragged} key={"k"} isDark={isDark}>
            <ThumbInner isDark={isDark} />
          </Thumb>
        )}
      />
    </div>
  )
}

const Container = styled.div`
  height: 36px;
  display: flex;
  width: 100%;
  cursor: grab;
`

const Track = styled.div`
  height: 6px;
  width: 100%;
  border-radius: 4px;
  background: ${(p) =>
    getTrackBackground({
      values: [p.currentValue],
      colors: p.isDark ? ["#fff", "#4F4E4E"] : ["#00456F", "#BEBEBE"],
      min: 0,
      max: 100,
    })};
  align-self: center;
`

const Thumb = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 16px;
  background-color: transparent;
  border-radius: 3px;
  transition: background 0.2s ease;

  ${p =>
    p.isDragged &&
    css`
      background-color: ${p =>
        p.isDark ? "rgba(255, 255, 255, 0.4)" : "rgba(0, 69, 111, 0.2)"};
      cursor: grabbing;
    `}
`

const ThumbInner = styled.div`
  height: 12px;
  width: 3px;
  background-color: ${p => (p.isDark ? "#fff" : "#00456f")};
`

export default PlayerProgress
