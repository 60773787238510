import React, { useEffect, useState, useRef } from "react"
import styled, {keyframes} from "styled-components"
import PQueue from "p-queue"
import { useAudioPlayer, useAudioPosition } from "react-use-audio-player"
import useInterval from "../../utils/useInterval"
import { createPlayback as create, updatePlayback } from "../../api/api"
import isLocalStorageNameSupported from "../../utils/isLocalStorageSupported"
import PlayerProgress from "../CarouselPlayer/Player.Progress"
import {
  EpisodeItem,
  Image,
  Body,
  Heading,
  Title,
  Bar,
  BarOuter,
  TimeStamp,
  BarContainer,
  PlayButton,
  ImageWrapper,
  Controls,
  TimeStamps,
  SkipButton
} from "./Carousel.Episode.Styles"
import PlayPauseIcon from "./Carousel.PlayPauseIcon.js"
import SkipForwardIcon from "../Player/Player.SkipForward"
import SkipBackIcon from "../Player/Player.SkipBack"

const queue = new PQueue({
  concurrency: 1,
  interval: 1000,
})
const analyticsInterval = 10 * 1000

const localStorageSupported = isLocalStorageNameSupported()
const localStorageProxy = localStorageSupported
  ? localStorage
  : {
      setItem: (key, value) => null,
      getItem: (key, value) => null,
    }

const CarouselEpisodeCurrent = ({ episode, setCurrentEpisode, autoPlay = true }) => {
  const [playing, setPlaying] = useState(false)
  const [duration, setDuration] = useState(null)
  const [position, setPosition] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [percent, setPercent] = useState(0)
  const [playback, setPlayback] = useState(null)
  const [currentPosition, setCurrentPosition] = useState(0)
  const audioPlayer = useRef(null)
  const requestRef = useRef(null)

  useEffect(() => {
    requestRef.current = requestAnimationFrame(renderSeekPos)
    return () => cancelAnimationFrame(requestRef.current)
  }, [])

  useEffect(() => {
    const createPlayback = async () => {
      await queue.add(async () => {
        const p = await create(
          episode.id,
          window.navigator.userAgent.replace(/\D+/g, "")
        )
        setPlayback(p.id)
      })
    }

    audioPlayer.current.addEventListener("loadedmetadata", (e) => {
      setDuration(e.target.duration)
      setLoaded(true)
      queue.add(() => {
        localStorageProxy.setItem(`${episode.id}-duration`, e.target.duration)
      })

      if (autoPlay) {
        audioPlayer.current.play()
        setPlaying(true)
      }
    })

    audioPlayer.current.addEventListener("timeupdate", (e) => {
      const currentTime = e?.target?.currentTime
      if (currentTime > 0) {
        // setPosition(e.target.currentTime)
        queue.add(() => {
          localStorageProxy.setItem(`${episode.id}-progression`, currentTime)
        })
      }
    })

    const saved = localStorageProxy.getItem(`${episode.id}-progression`)
    if (saved) {
      audioPlayer.current.currentTime = saved
    }

    audioPlayer.current.addEventListener("play", (e) => {
      if (!playback) {
        createPlayback()
      }
    })

    return (e) => {
      audioPlayer.current.removeEventListener("timeupdate", e)
      audioPlayer.current.removeEventListener("loadedmetadata", e)
      audioPlayer.current.removeEventListener("play", e)
    }
  }, [episode])


  const getTime = (time) => {
    if (time === 0) {
      return "0:00"
    }
    if (!isNaN(time)) {
      return (
        Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2)
      )
    }
  }

  const handleScrubber = (value) => {
    const valueInSeconds = (value[0] / 100) * duration
    setPercent(value[0] / 100)
    audioPlayer.current.currentTime = valueInSeconds
  }

  const handleSkip = (skipSeconds) => {
    audioPlayer.current.currentTime = position + skipSeconds
  }

  const handlePlayBtn = () => {
    if (!playing) {
      audioPlayer.current.play()
      setPlaying(true)
    } else {
      audioPlayer.current.pause()
      setPlaying(false)
    }
  }

  const renderSeekPos = () => {
    setPosition(audioPlayer.current.currentTime)
    requestRef.current = requestAnimationFrame(renderSeekPos)
  }

  return (
    <EpisodeItem>
      <ImageWrapper>
        <Image
          src={`${episode.imageUrl}?h=260&w=260&fit=crop`}
          alt={episode.longTitleCarousel || episode.title}
        />
      </ImageWrapper>
      <Body>
        <Heading>{episode.seriesTitle}</Heading>
        <Title>{episode.longTitleCarousel || episode.title}</Title>
        <Controls>
          <PlayButton onClick={() => handlePlayBtn()}>
            <PlayPauseIcon
              status={!loaded ? "loading" : playing ? "playing" : "paused"}
            />
          </PlayButton>
          <audio ref={audioPlayer} preload="metadata">
            <source src={episode.file} type="audio/mp3" />
          </audio>
          {!loaded ? (
            <LoadingTextOuter>
              <LoadingText>
                <Letter>L</Letter>
                <Letter>a</Letter>
                <Letter>s</Letter>
                <Letter>t</Letter>
                <Letter>e</Letter>
                <Letter>r</Letter>
                <Letter>.</Letter>
                <Letter>.</Letter>
                <Letter>.</Letter>
              </LoadingText>
            </LoadingTextOuter>
          ) : (
            <BarOuter>
              <Bar>
                <BarContainer>
                  <PlayerProgress
                    progression={position / duration}
                    onChange={handleScrubber}
                  />
                </BarContainer>
              </Bar>
              <TimeStamps>
                <div>
                  <SkipButton
                    onClick={() => handleSkip(-10)}
                    style={{ marginRight: "6px" }}
                  >
                    <SkipBackIcon />
                  </SkipButton>
                  <TimeStamp>{position ? getTime(position) : "0.00"}</TimeStamp>
                </div>
                <div>
                  <TimeStamp>{getTime(duration)}</TimeStamp>
                  <SkipButton
                    onClick={() => handleSkip(30)}
                    style={{ marginLeft: "6px" }}
                  >
                    <SkipForwardIcon />
                  </SkipButton>
                </div>
              </TimeStamps>
            </BarOuter>
          )}
        </Controls>
      </Body>
    </EpisodeItem>
  )
}



const LoadingTextOuter = styled.div`
  margin-left: 6px;
  margin-top: 12px;
  align-self: center;
`
const LoadingText = styled.div`
  color: #767676;
`

const loadingF = keyframes`
    0% {opacity: 0.3;}
    100% {opacity: 1;}
`

const Letter = styled.span`
  animation-name: ${loadingF};
  animation-duration: 1.6s;
  animation-iteration-count: infinite;
  animation-direction: linear;

  &:nth-child(1) {animation-delay: .48s;}
  &:nth-child(2) {animation-delay: .6s;}
  &:nth-child(3) {animation-delay: .72s;}
  &:nth-child(4) {animation-delay: .84s;}
  &:nth-child(5) {animation-delay: .96s;}
  &:nth-child(6) {animation-delay: 1.08s;}
  &:nth-child(7) {animation-delay: 1.2s;}
  &:nth-child(8) {animation-delay: 1.32s;}
  &:nth-child(9) {animation-delay: 1.44s;}
`

export default CarouselEpisodeCurrent
