import React from "react"
import {
  EpisodeItem,
  Image,
  Body,
  Heading,
  PlayButton,
  BarPlaceholder,
  ImageWrapper,
  Title,
  Controls
} from "./Carousel.Episode.Styles"
import PlayPauseIcon from "./Carousel.PlayPauseIcon.js"

const CarouselEpisode = ({ episode, setCurrentEpisode }) => {
  return (
    <EpisodeItem>
      <ImageWrapper>
        <Image
          src={`${episode.imageUrl}?h=260&w=260&fit=crop`}
          alt={episode.longTitleCarousel || episode.title}
        />
      </ImageWrapper>
      <Body>
        <Heading>{episode.seriesTitle}</Heading>
        <Title>{episode.longTitleCarousel || episode.title}</Title>
        <Controls>
          <PlayButton onClick={() => setCurrentEpisode(episode)}>
            <PlayPauseIcon />
          </PlayButton>
          <BarPlaceholder />
        </Controls>
      </Body>
      <div style={{ height: "20px" }} />
    </EpisodeItem>
  )
}

export default CarouselEpisode
