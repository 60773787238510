import styled from "styled-components"

export const EpisodeItem = styled.div`
  position: relative;
  margin: 0;
  padding: 0 12px 12px;
  background-size: cover;
`

export const ImageWrapper = styled.div`
  position: relative;
`

export const Image = styled.img`
  display: block;
  width: auto;
  max-width: 100%;
  height: auto;
  margin-bottom: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
`

export const Body = styled.div`
  flex-basis: 100%;
`

export const Heading = styled.h2`
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 3px;
  color: #01476d;

  @media only screen and (max-width: 320px) {
    margin-bottom: 6px;
  }
`

export const Title = styled.p`
  font-size: 16px;
  margin: 0;
`

export const Bar = styled.div`
  display: flex;
  align-items: center;
`

export const TimeStamp = styled.span`
  display: inline-block;
  color: #767676;
  padding: 0 6px;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }
`

export const BarContainer = styled.div`
  flex-basis: 100%;
`

export const BarOuter = styled.div`
  padding-left: 8px;
  flex-basis: calc(100% - 36px);
`

export const PlayButton = styled.button`
  background-color: transparent;
  padding: 0;
  border: 0;
  z-index: 10;
  height: 36px;
  width: 36px;
  margin-top: 12px;
  cursor: pointer;
  color: #00456f;
  border-radius: 50%;
  transition: box-shadow 0.2s ease, color 0.2s ease;

  > svg {
    display: block;
  }

  &:hover,
  &:active,
  &:focus {
    outline: none;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    color: #0075b0;
  }
`

export const SkipButton = styled.button`
  background-color: transparent;
  padding: 0;
  border: 0;
  cursor: pointer;
  color: #00456f;
  transition: color 0.2s ease;

  > svg {
    display: block;
  }

  &:hover,
  &:active,
  &:focus {
    outline: none;
    color: #0075b0;
  }
`

export const BarPlaceholder = styled.div`
  height: 54px;
`

export const Controls = styled.div`
  display: flex;
  align-items: center;
`

export const TimeStamps = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
  }
`
