import React from "react"
import Player from "./Player/Player"
import styled from "styled-components"

const Episode = ({ episode, isDark }) => {
  return (
      <div>
        <Outer isDark={isDark}>
          <div>
            <Img
              src={`${episode.image.asset.url}?h=121&w=121&fit=crop`}
              alt={episode.title}
            />
          </div>
          <Body>
            <SeriesTitle isDark={isDark}>{episode.series.title}</SeriesTitle>
            <StyledPlayer
              mediaUrl={episode.s3Location || episode.media.aFile.asset.url}
              episodeId={episode._id}
              bodyText={episode.longTitle || episode.title}
              isDark={isDark}
            />
          </Body>
        </Outer>
      </div>
  )
}


const StyledPlayer = styled(Player)`
  flex-wrap: wrap;

  > *:last-child {
    flex-basis: 100%;
    padding-left: 0;
  }
`

const Outer = styled.article`
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${(p) => (p.isDark ? "#302F2F" : "#E9E8E6")};
  color: ${(p) => (p.isDark ? "#fff" : "inherit")};
  padding-right: 12px;

  /* @media only screen and (max-width: 420px) {
    padding-bottom: 38px;
  } */
`

const Img = styled.img`
  display: block;
  width: 121px;
  height: 121px;
  margin-right: 18px;

  /* @media only screen and (max-width: 420px) {
    width: 68px;
    height: 68px;
    margin-right: 12px;
  } */
`

const Body = styled.div`
  flex-basis: 100%;
`

const SeriesTitle = styled.span`
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  color: ${(p) => (p.isDark ? "#fff" : "#767676")};
  font-weight: bold;
  margin-bottom: 6px;
  padding-top: 6px;
`

const Heading = styled.h1`
  font-size: 16px;
  font-weight: normal;
  margin: 0 0 12px;

  /* @media only screen and (max-width: 320px) {
    margin-bottom: 6px;
  } */
`

// const Message = styled.p`
//   background-color: #f3f1ef;
//   line-height: 20px;
//   font-size: 12px;
//   text-align: center;
//   margin: 0;
//
//   > a {
//     color: #00456f;
//     text-decoration: none;
//   }
// `

export default Episode
