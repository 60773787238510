import React, { useEffect, useState } from "react"
import { Router } from "@reach/router"
import "./App.css"
import { getEpisode, getLatest, getCarousel } from "./api/api"
import usePlayerType from "./utils/usePlayerType"
import Episode from "./components/Episode"
import FeaturedEpisode from "./components/FeaturedEpisode"
import Carousel from "./components/Carousel/Carousel"

const Latest = props => {
  const {isDark} = usePlayerType()
  const [episode, setEpisode] = useState(null)



  useEffect(() => {
    const handleFetch = async () => {
      const content = await getLatest(props.series)
      setEpisode(content)
    }
      
    handleFetch()
  }, [props.series])

  if (!episode) {
    return null
  }

  return <Episode episode={episode} isDark={isDark} />
}

const EpisodeSlug = props => {
  const {isDark} = usePlayerType()
  const [episode, setEpisode] = useState(null)



  useEffect(() => {
    const handleFetch = async () => {
      const content = await getEpisode(props.slug)
      setEpisode(content)
    }
      
    handleFetch()
  }, [props.slug])

  if (!episode) {
    return null
  }

  return <Episode episode={episode} isDark={isDark} />
}

const CarouselLatest = props => {
  const [carousel, setCarousel] = useState(null)



  useEffect(() => {
    const handleFetch = async () => {
      const content = await getCarousel(props.slug) // TODO: Make a content type or something for carousels…
      setCarousel(content)
    }
      
    handleFetch()
  }, [props.slug])

  if (!carousel) {
    return null
  }

  return <Carousel carousel={carousel} />
}

const FeaturedEpisodeSlug = props => {
  const {isDark} = usePlayerType()
  const [episode, setEpisode] = useState(null)



  useEffect(() => {
    const handleFetch = async () => {
      const content = await getEpisode(props.slug)
      setEpisode(content)
    }
      
    handleFetch()
  }, [props.slug])

  if (!episode) {
    return null
  }

  return <FeaturedEpisode episode={episode} isDark={isDark} />
}

const FeaturedEpisodeLatest = props => {
  const { isDark } = usePlayerType()
  const [episode, setEpisode] = useState(null)

  useEffect(() => {
    const handleFetch = async () => {
      const content = await getLatest(props.series)
      setEpisode(content)
    }
      
    handleFetch()
  }, [props.series])

  if (!episode) {
    return null
  }

  return <FeaturedEpisode episode={episode} isDark={isDark} />
}

const App = () => {
  return (
    <Router>
      <Latest path={"/player/serie/:series"} />
      <EpisodeSlug path={"/player/:slug"} />
      <EpisodeSlug path={"/player/"} />
      <CarouselLatest path={"/carousel/:slug"} />
      <FeaturedEpisodeSlug path={"/featured-player/:slug"} />
      <FeaturedEpisodeLatest path={"/featured-player/"} />
    </Router>
  )
}

export default App
