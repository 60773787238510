import React from "react"
import styled from "styled-components"

const SkipBack = ({ icon, isDark }) => (
  <Icon
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.00004 0C6.70966 0 4.61969 0.86653 3.02936 2.2793L1.50007 0.75V5.25H6.00005L4.09137 3.34131C5.40719 2.19827 7.11607 1.5 9.00004 1.5C13.1509 1.5 16.5 4.8491 16.5 9C16.5 13.1509 13.1509 16.5 9.00004 16.5C4.84915 16.5 1.50007 13.1509 1.50007 9C1.50147 8.90062 1.48311 8.80194 1.44605 8.70972C1.40899 8.61749 1.35397 8.53355 1.28419 8.46277C1.21441 8.392 1.13125 8.33579 1.03956 8.29743C0.947868 8.25907 0.849465 8.23932 0.750072 8.23932C0.650678 8.23932 0.552276 8.25907 0.460584 8.29743C0.368891 8.33579 0.285737 8.392 0.215954 8.46277C0.146171 8.53355 0.0911507 8.61749 0.0540905 8.70972C0.0170304 8.80194 -0.00133052 8.90062 7.50052e-05 9C7.50052e-05 13.9616 4.03846 18 9.00004 18C13.9616 18 18 13.9616 18 9C18 4.0384 13.9616 0 9.00004 0ZM11.0157 6.26221C9.65366 6.26221 8.79643 7.28319 8.79643 9.03369C8.79643 10.7842 9.64166 11.8308 11.0157 11.8315C12.3851 11.8315 13.2349 10.7811 13.2349 9.03809C13.2349 7.28009 12.3746 6.26221 11.0157 6.26221ZM6.10112 6.4043L4.75787 7.31982V8.47705L6.03228 7.61572H6.10112V11.6895H7.44585V6.4043H6.10112ZM11.0157 7.29492C11.5287 7.29492 11.8726 7.87269 11.8726 9.03369C11.8726 10.2022 11.5287 10.7988 11.0157 10.7988C10.5027 10.7988 10.1617 10.2022 10.1617 9.03369C10.1617 7.87269 10.5027 7.29492 11.0157 7.29492Z"
      fill={isDark ? "#fff" : "#00456f"}
    />
  </Icon>
)

const Icon = styled.svg`
  display: block;
`

export default SkipBack
