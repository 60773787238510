import React from "react"
import styled, {keyframes} from "styled-components"

const CarouselPlayPauseIcon = ({ status }) => {

    if (status === "loading") {
      return (
        <LoaderWrapper>
          <Dot />
          <Dot />
          <Dot />
        </LoaderWrapper>
      )
    }

  if (status === "playing") {
    return (
      <SVG viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.9998 0.678711C33.7732 0.678711 43.3209 10.2264 43.3209 21.9998C43.3209 33.7732 33.7732 43.3209 21.9998 43.3209C10.2264 43.3209 0.678711 33.7732 0.678711 21.9998C0.678711 10.2264 10.2264 0.678711 21.9998 0.678711ZM14.6948 11.0854H19.8511C20.3237 11.0854 20.7104 11.4722 20.7104 11.9448V32.0542C20.7104 32.5269 20.3237 32.9136 19.8511 32.9136H14.6948C14.2222 32.9136 13.8354 32.5269 13.8354 32.0542V11.9448C13.8354 11.4722 14.2222 11.0854 14.6948 11.0854ZM24.1479 11.0854H29.3042C29.7769 11.0854 30.1636 11.4722 30.1636 11.9448V32.0542C30.1636 32.5269 29.7769 32.9136 29.3042 32.9136H24.1479C23.6753 32.9136 23.2886 32.5269 23.2886 32.0542V11.9448C23.2886 11.4722 23.6753 11.0854 24.1479 11.0854Z"
        />
      </SVG>
    )
  }

  return (
    <SVG viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42ZM16.6379 31.2299L30.1875 21L16.6379 10.7701L16.6379 31.2299Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42ZM16.6379 31.2299L30.1875 21L16.6379 10.7701L16.6379 31.2299Z"
      />
      <path
        d="M30.1875 21L31.3926 22.5962L33.5067 21L31.3926 19.4038L30.1875 21ZM16.6379 31.2299H14.6379V35.2459L17.843 32.8261L16.6379 31.2299ZM16.6379 10.7701L17.843 9.17391L14.6379 6.75407V10.7701H16.6379ZM40 21C40 31.4934 31.4934 40 21 40V44C33.7025 44 44 33.7025 44 21H40ZM21 2C31.4934 2 40 10.5066 40 21H44C44 8.29745 33.7025 -2 21 -2V2ZM2 21C2 10.5066 10.5066 2 21 2V-2C8.29745 -2 -2 8.29745 -2 21H2ZM21 40C10.5066 40 2 31.4934 2 21H-2C-2 33.7025 8.29745 44 21 44V40ZM28.9824 19.4038L15.4328 29.6338L17.843 32.8261L31.3926 22.5962L28.9824 19.4038ZM15.4328 12.3662L28.9824 22.5962L31.3926 19.4038L17.843 9.17391L15.4328 12.3662ZM18.6379 31.2299L18.6379 10.7701H14.6379L14.6379 31.2299H18.6379Z"
        mask="url(#path-1-inside-1)"
      />
    </SVG>
  )
}

const lc = keyframes`
    0 {transform: translate(0,0);}
    50% {transform: translate(0,2px);}
    100% {transform: translate(0,-2px);}
`

const SVG = styled.svg`
  fill: currentColor;
`

const LoaderWrapper = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #00456f;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Dot = styled.div`
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 1px;

  &:nth-child(1) {
    animation: ${lc} 1s 0.1s ease-in-out infinite alternate;
  }

  &:nth-child(2) {
    animation: ${lc} 1s 0.2s ease-in-out infinite alternate;
  }

  &:nth-child(3) {
    animation: ${lc} 1s 0.3s ease-in-out infinite alternate;
  }
`

export default CarouselPlayPauseIcon
