import React from "react"
import Player from "./Player/Player"
import styled from "styled-components"
import { AudioPlayerProvider } from "react-use-audio-player"

const mobileBreakPoint = 680

const FeaturedEpisode = ({ episode }) => {
  return (
    <AudioPlayerProvider>
      <div>
        <Outer>
          <Inner>
            <div>
              <Img
                src={`${episode.image.asset.url}?h=214&w=214&fit=crop`}
                alt={episode.title}
              />
            </div>
            <Body>
              <Heading>{episode.longTitle || episode.title}</Heading>
              <StyledPlayer
                mediaUrl={episode.s3Location || episode.media.aFile.asset.url}
                episodeId={episode._id}
                bodyText={episode.ledeText}
                hideBodyTextBreakpoint={mobileBreakPoint}
                heading={episode.longTitle || episode.title}
                showHeadingBreakpoint={mobileBreakPoint}
                largePlayBtn
                isFeatured
              />
            </Body>
          </Inner>
          <MobileOnlyBodyText>{episode.ledeText}</MobileOnlyBodyText>
        </Outer>
        <Message>
          Last ned BT Lytt i{" "}
          <a href="https://play.google.com/store/apps/details?id=no.lytt.bt&hl=no">
            Google Play
          </a>{" "}
          |{" "}
          <a href="https://apps.apple.com/no/app/bt-lytt/id1447105984?l=nb">
            App Store
          </a>
        </Message>
      </div>
    </AudioPlayerProvider>
  )
}

const Outer = styled.article`
  margin-bottom: 12px;
  overflow: hidden;
  background-color: #e9e8e6;
  color: #333333;

  @media only screen and (max-width: ${mobileBreakPoint}px) {
    padding: 9px;
  }
`

const Inner = styled.article`
  position: relative;
  display: flex;
  align-items: stretch;
`

const StyledPlayer = styled(Player)`
  flex-wrap: wrap;

  > *:last-child {
    flex-basis: 100%;
    padding-left: 0;
  }
`

const Img = styled.img`
  display: block;
  width: 209px;
  height: 209px;
  margin-right: 18px;

  @media only screen and (max-width: ${mobileBreakPoint}px) {
    width: 86px;
    height: 86px;
    margin-right: 12px;
  }
`

const Body = styled.div`
  flex-basis: 100%;
  padding: 18px 18px 18px 0;

  @media only screen and (max-width: ${mobileBreakPoint}px) {
    padding: 0;
  }
`

const Heading = styled.h1`
  font-size: 22px;
  font-weight: bold;
  font-family: "Georgia", serif;
  color: #333333;
  margin: 0 0 18px;
  height: 50px;
  text-overflow: ellipsis;
  overflow: hidden;

  @media only screen and (max-width: ${mobileBreakPoint}px) {
    display: none;
  }
`

const Message = styled.p`
  font-size: 14px;
  color: #767676;
  margin: 0;

  > a {
    color: #00456f;
    text-decoration: none;
  }
`

const MobileOnlyBodyText = styled.p`
  margin: 12px 0 0;
  font-size: 15px;
  line-height: 1.3;
  height: 57px;
  overflow: hidden; 

  @media only screen and (min-width: ${mobileBreakPoint + 1}px) {
    display: none;
  }
`

export default FeaturedEpisode
